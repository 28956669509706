<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      icon="mdi-bed"
      color="info"
      text
      v-if="balancesOpen.length > 0"
    >
      <v-row align="center">
        <v-col class="grow">
          Bitte führe bis zum Notentermin den Semesterabschluss für folgende
          Klassen durch!
        </v-col>
        <v-col class="shrink">
          <v-btn
            depressed
            color="info"
            v-for="balance in balancesOpen"
            :key="'btn-' + balance.schoolClass.id"
            :to="{
              name: 'AbsencesBalance',
              params: { schoolClass: balance.schoolClass },
            }"
            >{{ balance.schoolClass.code }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-card outlined class="mb-4" :loading="loadingJustifications">
      <v-system-bar>Begründungen pendent bei mir</v-system-bar>
      <JustificationList :items="justificationsTeacherPending" />
    </v-card>

    <v-card outlined class="mb-4" :loading="loadingJustifications">
      <v-system-bar
        >Begründungen aktuell (bewilligt aber noch nicht vorbei)</v-system-bar
      >
      <JustificationList :items="justificationsTeacherApproved" />
    </v-card>

    <v-card outlined class="mb-4" :loading="loadingJustifications">
      <v-system-bar>Begründungen pendent bei Schüler:in</v-system-bar>
      <JustificationList :items="justificationsStudent" />
    </v-card>

    <v-card outlined :loading="loadingAbsences">
      <v-system-bar v-if="$vuetify.breakpoint.xs"
        >Absenzen ohne Begründung (gruppiert)</v-system-bar
      >
      <v-system-bar v-else
        >Absenzen ohne Begründung (nach Schüler:in gruppiert)</v-system-bar
      >
      <AbsenceListGrouped :items="absencesGrouped" @updated="fetchAbsences" />
    </v-card>
    <router-view @updated="fetchJustifications()" :schoolClass="schoolClass" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AbsenceListGrouped from "common/components/AbsenceListGrouped.vue";
import JustificationList from "common/components/JustificationList.vue";

export default defineComponent({
  components: { AbsenceListGrouped, JustificationList },
  props: ["schoolClass"],
  data() {
    return {
      absencesGrouped: [],
      balances: [],
      justificationsStudent: [],
      justificationsTeacher: [],
      loadingAbsences: false,
      loadingBalances: false,
      loadingJustifications: false,
    };
  },
  computed: {
    balancesOpen() {
      return this.balances.filter((el) => el.open);
    },
    balancesDone() {
      return this.balances.filter((el) => el.done);
    },
    justificationsTeacherApproved() {
      return this.justificationsTeacher.filter(
        (el) => el.status.code == "approved"
      );
    },
    justificationsTeacherPending() {
      return this.justificationsTeacher.filter(
        (el) => el.status.code == "submitted" || el.status.code == "created"
      );
    },
  },
  methods: {
    async fetchAbsences() {
      this.loadingAbsences = true;
      const absences = (
        await this.apiList({
          resource: "absence/absence",
          query: `pending&schoolclass=${this.schoolClass.id}`,
        })
      ).sort((a, b) => {
        const aValue = a.date + a.startTime;
        const bValue = b.date + b.startTime;
        return aValue.localeCompare(bValue);
      });

      this.absencesGrouped = [];

      for (const absence of absences) {
        let entry = this.absencesGrouped.find(
          (el) => el.student.id == absence.student.id
        );

        if (entry) {
          entry.absences.push(absence);
        } else {
          entry = { student: absence.student, absences: [absence] };
          this.absencesGrouped.push(entry);
        }
      }

      this.loadingAbsences = false;
    },
    async fetchBalances() {
      this.loadingBalances = true;
      this.balances = await this.apiList({
        resource: "absence/balance",
      });
      this.loadingBalances = false;
    },
    async fetchJustifications() {
      this.loadingJustifications = true;
      this.justificationsTeacher = await this.apiList({
        resource: "absence/justification",
        query: `status=pendingTeacher&schoolclass=${this.schoolClass.id}`,
      });
      this.justificationsStudent = await this.apiList({
        resource: "absence/justification",
        query: `status=pendingStudent&schoolclass=${this.schoolClass.id}`,
      });
      this.loadingJustifications = false;
    },
    async fetchData() {
      await this.fetchBalances();
      if (!this.schoolClass) return;
      await this.fetchAbsences();
      await this.fetchJustifications();
    },
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
